
/*::v-deep .el-breadcrumb {*/
    /*.el-breadcrumb__inner {*/
        /*color: #000;*/
    /*}*/
/*}*/
.contact-wrapper {
    width: 100%;
    padding: 20px 0;
    height: calc(100% - 40px);
    overflow: auto;
    .contact-content {
        height: 100%;
        width: 1200px;
        margin: 0 auto;
        .contact-detail {
            padding: 40px 0;
            border-top: 1px solid #EAEAEA;
            margin-top: 20px;
        }
    }
}
